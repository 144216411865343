<template>
  <div>

    <van-nav-bar right-text="分享"
                 @click-right="onClickRight">
      <template #left>
        <van-image radius="18px"
                   height="40px"
                   width="40px"
                   @click="txClick"
                   src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/toux.jpg" />
      </template>
      <template #title>
        <span style="font-weight:600;color:#0af">星球服务·微信端</span>
      </template>
    </van-nav-bar>

    <van-notice-bar left-icon="volume-o"
                    text="标记红点为待适配栏目，链接指向主站"
                    background="rgb(0 170 255 / 22%)" />
    <van-grid clickable
              :column-num="3">
      <van-grid-item icon="hot-o"
                     text="面试宝典"
                     to="/question" />

      <van-grid-item icon="refund-o"
                     text="精品课"
                     url="https://www.golangroadmap.com/pay/#%E7%B2%BE%E5%93%81%E8%AF%BE"
                     dot />

      <van-grid-item icon="todo-list-o"
                     text="每日博文"
                     url="https://www.golangroadmap.com/blog/list.html"
                     dot />

      <van-grid-item icon="newspaper-o"
                     text="Go宝典"
                     url="https://www.golangroadmap.com/blog/list.html"
                     dot />
      <van-grid-item icon="video-o"
                     text="Go视界"
                     url="https://www.golangroadmap.com/v-world/"
                     dot />

      <van-grid-item icon="tosend"
                     text="Go小课"
                     url="https://www.golangroadmap.com/class/"
                     dot />

      <van-grid-item icon="sign"
                     text="Go小考"
                     url="https://www.golangroadmap.com/exam/"
                     dot />

      <van-grid-item icon="smile-o"
                     text="面经分享"
                     url="https://www.golangroadmap.com/experience/"
                     dot />

      <van-grid-item icon="description"
                     text="面试宝典"
                     url="https://www.golangroadmap.com/interview/"
                     dot />

      <van-grid-item icon="apps-o"
                     text="Go仓库"
                     url="https://www.golangroadmap.com/github/"
                     dot />

      <van-grid-item icon="calendar-o"
                     text="Go月刊"
                     url="https://www.golangroadmap.com/github/"
                     dot />

      <van-grid-item icon="hotel-o"
                     text="Go公司"
                     url="https://www.golangroadmap.com/company/"
                     dot />

      <van-grid-item icon="certificate"
                     text="Go岗位"
                     url="https://www.golangroadmap.com/job/"
                     dot />
      <van-grid-item icon="hot-sale-o"
                     text="Go周边"
                     url="https://www.golangroadmap.com/goods/"
                     dot />
      <van-grid-item icon="down"
                     text="Go下载"
                     url="https://www.golangroadmap.com/download/"
                     dot />

      <van-grid-item icon="good-job-o"
                     text="推荐图书"
                     url="https://www.golangroadmap.com/books/paper.html"
                     dot />

      <van-grid-item icon="bulb-o"
                     text="摸鱼专区"
                     to="play"
                     dot />

      <van-grid-item icon="friends-o"
                     text="友情链接"
                     url="https://www.golangroadmap.com/flinks/"
                     dot />
    </van-grid>
    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { ImagePreview, Dialog, ShareSheet, Form, Field, Icon, Button, NoticeBar, Image as VanImage, Col, Row, NavBar, Grid, GridItem } from 'vant';

export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,

    [Dialog.Component.name]: Dialog.Component,
    [ShareSheet.name]: ShareSheet,
    [Form.name]: Form,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,
    [VanImage.name]: VanImage,
    [Col.name]: Col,
    [Row.name]: Row,
    [NavBar.name]: NavBar,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid
  },
  data () {
    return {

      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        { name: '专属海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
        // { name: '二维码', icon: 'qrcode' },
      ],
    };
  },
  methods: {
    txClick() {
      this.$router.push("user");      
    },
    //打开分享
    onClickRight () {
      this.showShare = true
    },
    //分享
    onSelect (shareOption) {

      if (shareOption.name == '专属海报') {

        var inviteImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/invite/' + window.localStorage.getItem("invitationCode") + '.jpg'

        ImagePreview({
          images: [
            inviteImage,
          ],
          closeable: true,
        });

        this.showShare = false;
      } else {
        Dialog({ message: '分享信息已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var shareUrl = "GOLANG ROADMAP一个很不错的网站：https://m.golangroadmap.com"
        this.$copyText(shareUrl, container)
      }

    }
  },
};
</script>

<style lang="less">
</style>
